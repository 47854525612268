.team__container{
    text-align: center;
}

.team__heading{
    margin-top: 3vw;
}

.team__photo{
    width: 15vw;
    height: 15vw;    
    margin-top: 3vw;
}


.team__description{
    text-align: center;
    margin-left: 15vw;
    margin-top: 2vw;
    width: 70vw;
    border-radius:20px;
    color: var(--font-medium);
    border: 0.5px solid var(--title-color);
    padding: 25px;
    
}

@media screen and (max-width: 576px) {
    .team__photo{
        width: 40vw;
        height: 40vw;    
        margin-top: 3vw;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .team__photo{
        width: 40vw;
        height: 40vw;    
        margin-top: 3vw;
    }  
}


