@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*=============== GOOGLE FONTS ===============*/

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;

  /*.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /*========== Font weight ==========*/
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*=============== BASE ===============*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 1rem;
  font-size: var(--normal-font-size);
}

body {
  background-color: hsl(0, 0%, 98%);
  background-color: var(--body-color);
  color: hsl(0, 0%, 46%);
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: hsl(0, 0%, 20%);
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 6rem 0 2rem;
}

.section__title {
  font-size: 2.25rem;
  font-size: var(--h1-font-size);
  color: hsl(0, 0%, 20%);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: 0.875rem;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*=============== LAYOUT ===============*/
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

/*=============== BUTTONS ===============*/
.button {
  display: inline-block;
  background-color: hsl(0, 0%, 20%);
  background-color: var(--title-color);
  color: #fff;
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: 500;
  font-weight: var(--font-medium);
}

.button:hover {
  background-color: hsl(0, 0%, 0%);
  background-color: var(--title-color-dark);
}

.button__icon {
  margin-left: 0.5rem;
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

.uil-apps{
  display: none;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .container {
    margin-left: 1.5rem;
    margin-left: var(--mb-1-5);
    margin-right: 1.5rem;
    margin-right: var(--mb-1-5);
  }

  .button {
    padding: 1rem 1.75rem;
    margin-top: 30px;
  }

  .button__icon {
    width: 22px;
    height: 22px;
  }

}

/* For medium devices */
@media screen and (max-width: 768px) {
  body {
    margin: 0 0 3rem 0;
    margin: 0 0 var(--header-height) 0;
  }

  .section {
    padding: 2rem 0 4rem;
  }

  .section__subtitle {
    margin-bottom: 3rem;
    margin-bottom: var(--mb-3);
  }
  /* added by me */
  .home__description{
    text-align: center;
    min-width: 90vw;
  }

  .home__img{
    margin-left: -65vw;
  }
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }

  .container {
    margin-left: 1rem;
    margin-left: var(--mb-1);
    margin-right: 1rem;
    margin-right: var(--mb-1);
  }
  
}

@media screen and (max-width: 769px) {
.uil-apps{
  display: contents;
}
}
.header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
    white-space: nowrap;
}

.nav{
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
}

.nav__logo, 
.nav__toggle{
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__logo{
    margin-top: 10px;
    margin-left: 7vw;
    width: 240px;
    height: auto;
}

.nav__list{
    display: flex;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
}

.nav__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-mdeium);
    transition: .3s;
}


.nav__icon, 
.nav__close, 
.nav__toggle{
    display: none;
 }

.active-link,
.nav__link:hover{
    color: var(--title-color-dark);
}

@media screen and (max-width: 768px) {
    .header{
        top: initial;
        bottom: 0;
    }

    .nav{
        height: var(--header-height);
    }

    .nav__menu{
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0,0,0,0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;

    }

    .show-menu{
        bottom: 0;

    }

    .nav__icon{
        font-size: 1.2rem;
    }

    .nav__close{
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__close:hover{
        color: var(--title-color-dark);
    }

    .nav__toggle{
        font-size: 1.1rem;
        cursor: pointer; 
    }
    
    .nav__list{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 2rem;
        gap: 2rem;
    }
    
    .nav__icon,
    .nav__close,
    .nav__toggle{
        display: block;

    }
  }

  @media screen and (max-width: 350px) {
    .nav__menu{
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list{
        grid-column-gap: 0;
        -webkit-column-gap: 0;
                column-gap: 0;
    }
  }

  @media screen and (max-width: 992px) {
    .nav__logo{
        margin-left: 1px;
    }
  }
.home__content {
    grid-template-columns: 116px repeat(2,1fr);
    padding-top: 5.5rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
    align-items: center;
}

.home__img{
    background: url(/static/media/ca_home.9b9efd06.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    -webkit-animation: profile__animate 8s ease-in-out infinite 1s;
            animation: profile__animate 8s ease-in-out infinite 1s;
}

@-webkit-keyframes profile__animate{
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

@keyframes profile__animate{
    0%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50%{
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100%{
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.home__social{
    display: grid;
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
    
}

.home__social-icon{
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__social-icon:hover{
    color: var(--title-color-dark);
}

.home__title{
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home__hand{
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}

.home__subtitle{
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__subtitle::before{
    position: absolute;
    content: '';
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
}

.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.home__container{
    grid-row-gap: 7rem;
    row-gap: 7rem;
}

.home__scroll{
    margin-left: 9.25rem;
}

.wheel{
    -webkit-animation: scroll 2s ease infinite;
            animation: scroll 2s ease infinite;
}

@-webkit-keyframes scroll{
    0%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    30%{
        -webkit-transform: translateY(3.75rem);
                transform: translateY(3.75rem);
    }
}

@keyframes scroll{
    0%{
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    30%{
        -webkit-transform: translateY(3.75rem);
                transform: translateY(3.75rem);
    }
}

.home__scroll-name{
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
    position: relative;
    top: -10px;
}

.home__scroll-arrow{
    position: relative;
    font-size: 1.25rem;
    color: var(--title-color);
    top: -7px;
}


@media screen and (max-width: 992px) {
    .home__content{
        grid-template-columns: 100px repeat(2,1fr);
        grid-column-gap: 1.25rem;
        -webkit-column-gap: 1.25rem;
                column-gap: 1.25rem;
    }
    
    .home__hand{
        width: 26px;
        height: 26px;
    }
    
    .home__subtitle{
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before{
        width: 42px;
        top: 0.8rem;
    }

    .home__description{
        max-width: inherit;
        margin-bottom: var(--mb-2-25);
        
    }

    .home__img{
        width: 250px;
        height: 250px;
    }

    .home__scroll{
        margin-left: 7.5rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .home__content{
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }

    .home__img{
        order: initial;
        justify-self: initial;
    }

    .home__data{
        grid-column: 1/3;
    }
    
    .home__img{
        width: 200px;
        height: 200px;
    }

    .home__scroll{
        margin-left: 7.5rem;
    }
    
  }
  
  @media screen and (max-width: 576px) {
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
     .home__hand{
        width: 22px;
        height: 22px;
     }
  }  


.uil-apps{
    display: none;
}



@media screen and (max-width: 769px) {
    .uil-apps{
      display: contents;
    }

    .home__content{
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }

    .home__scroll{
        display: none;
    }
    }







.about__container{
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
}

.about__img{
    width: 350px;
    border-radius: 1.5rem;
    justify-self: center;
}

.about__info{
    grid-template-columns: repeat(3,140px);
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
    margin-top: 35px;
}

.about__box{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon{
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-5);
}

.about__title{
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle{
    font-size: var(--tiny-font-size);
}

.about__description{
    /* padding: 0 4rem 0 0; */
    margin-bottom: var(--mb-2-5);
}


@media screen and (max-width: 992px) {
    .about__container{
        grid-template-columns: 1fr;
        grid-row-gap: 2.5rem;
        row-gap: 2.5rem;
    }

    .about__img{
        width: 220px;
        margin-top: 25px;

    }

    .about__box{
        padding: 0.75rem 0.5rem;
    }

    .about__data{
        text-align: center;
    }

    .about__info{
        justify-content: center;
        margin-top: -10px;
    }

    /* .about__description{
        padding: 0 5rem;
        margin-bottom: 2rem;
    } */
  
  }
  
  
  @media screen and (max-width: 576px) {
    .about__info{
        grid-template-columns: repeat(3,1fr);

        
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .about__info{
        grid-template-columns: repeat(2,1fr);
    } 
    
  }
  
.services__container{
    grid-template-columns: repeat(3,270px);
    justify-content: center;
    grid-column-gap: 1.8rem;
    -webkit-column-gap: 1.8rem;
            column-gap: 1.8rem;
}

.services__content{
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--container-color);
    padding: 6rem 0 2rem 2.5rem;
}

.service__icon{
    display: block;
    font-size: 1.6rem;
    color: var(--title-color);
    margin-bottom: var(--mb-1);
}

.services__title{
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}

.services__button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
    cursor: pointer;
}

.services__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.services__button:hover .services__button-icon{
    -webkit-transform: translateX(00.25rem);
            transform: translateX(00.25rem);
}

.services__modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba( 0,0,0,0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.services__modal-content{
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.services__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.services__modal-title,
.services__modal-description{

    text-align: center;
}

.services__modal-title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.services__modal-description{
    font-size: var(--small-font-size);
    /* padding: 0 3.5rem; */
    margin-bottom: var(--mb-2);       
}


.services__modal-service{
    grid-row-gap: 0.7rem;
    row-gap: 0.7rem;

}

.services__modal-service{
    display: flex;
    align-items: center;
    grid-column-gap: .5rem;
    -webkit-column-gap: .5rem;
            column-gap: .5rem;
}

.services__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}

.services__modal-info{
    font-size: var(--small-font-size);
    
}


.active-modal{
    opacity: 1;
    visibility: visible;
}



@media screen and (max-width: 992px) {
    .services__container{
        grid-template-columns: repeat(3,218px);
    }
  
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .services__container{
        grid-template-columns: repeat(2,1fr);
    }

    .services__content{
        padding: 3.5rem 0.5rem 1.2rem 1.5rem;
    }

    .services__icon{
        font-size: 1.5rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .services__modal-contentpx{
        padding: 4.5rem 1.5rem 2.5rem;
    }

    .services__modal-description{
        padding: 0;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .services__container{
        grid-template-columns: -webkit-max-content;
        grid-template-columns: max-content;
    } 
    
    .services__content{
        padding-right: 6rem;
    }
  }

  .section__subtitle{
    width: 50vw;
    margin-left: 25vw;
    margin-top: 1vw;
  }
.team__container{
    text-align: center;
}

.team__heading{
    margin-top: 3vw;
}

.team__photo{
    width: 15vw;
    height: 15vw;    
    margin-top: 3vw;
}


.team__description{
    text-align: center;
    margin-left: 15vw;
    margin-top: 2vw;
    width: 70vw;
    border-radius:20px;
    color: var(--font-medium);
    border: 0.5px solid var(--title-color);
    padding: 25px;
    
}

@media screen and (max-width: 576px) {
    .team__photo{
        width: 40vw;
        height: 40vw;    
        margin-top: 3vw;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .team__photo{
        width: 40vw;
        height: 40vw;    
        margin-top: 3vw;
    }  
}



.contact__container{
    grid-template-columns: repeat(2,-webkit-max-content);
    grid-template-columns: repeat(2,max-content);
    justify-content: center;
    grid-column-gap: 6rem;
    -webkit-column-gap: 6rem;
            column-gap: 6rem;
    padding-bottom: 3rem;
}

.contact__info{
    display: grid;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: 300px;
}

.contact__form{
    width: 360px;
}

.contact__title{
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__card{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
}

.contact__card-icon{
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data{
    font-size: var(--small-font-size);
}

.contact__card-title{
    font-weight: var(--font-medium);
}

.contact__card-data{
    display: block;
    margin-bottom: var(--mb-0-75);
}

.contact__button{
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 0.25rem;
    -webkit-column-gap: 0.25rem;
            column-gap: 0.25rem;
}

.contact__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.contact__button:hover .contact__button-icon{
    -webkit-transform: translate(0.25rem);
            transform: translate(0.25rem);
}

#linkedin{
    padding: 10px;
}

.contact__form-div{
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0,0,0,0.3);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact__form-tag{
    position: absolute;
    top: -0.7rem;
    left: 1.2rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
}

.contact__form-area{
    height: 11rem;
}


.contact__form-area textarea{
    resize: none;
} 



@media screen and (max-width: 992px) {
    .contact__container{
        grid-column-gap: 3rem;
        -webkit-column-gap: 3rem;
                column-gap: 3rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .contact__container{
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
        row-gap: 3rem;
    }  

    .contact__info{
        justify-content: center;
    }

    .contact__form{
        margin: 0 auto;
    }
}
  @media screen and (max-width: 576px) {
    .contact__info{
        grid-template-columns: 1fr;
    }

    .contact__form{
        width: 100%;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    
  }

.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0,0,0,0.1);
}

.footer__container{
    padding: 2rem 0 6rem;
}

.footer__title
,.footer__link{
    color: var(--title-color);
}

.footer__title{
    text-align: center;
    margin-bottom: var(--mb-2);
}

.footer__link:hover{
    color: var(--title-color-dark);
}

.footer__list{
    display: flex;
    justify-content: center;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer__social{
    display: flex;
    justify-content: center;
    grid-column-gap: 1.125rem;
    -webkit-column-gap: 1.125rem;
            column-gap: 1.125rem;
}

.footer__social-link{
    background-color: var(--title-color);
    color: var(--container-color);
    font-size: 1.25rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer__social-link:hover{
    background-color: var(--title-color-dark);
}

@media screen and (max-width: 992px) {
    .footer__social-link{
        padding: 0.25rem;
        border-radius: 0.25rem;
        font-size: 1rem;
    }
  
  }
